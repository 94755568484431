import React, { useEffect } from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import {
  Button,
  Grid,
  useMediaQuery,
  Container,
  Card,
  CardMedia,
  Typography,
  CardContent,
} from "@material-ui/core"
import { Helmet } from "react-helmet"
import Checkout from "../components/checkout"
import Hero from "../components/hero"
import Tear from "../img/tear.jpg"

const useStyles = makeStyles(theme => ({
  bottom: {
    paddingBottom: "20px",
  },
  mainPaper: {
    backgroundColor: "#ffffffdd",
  },
  bodyText: {
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    textAlign: "center",
    padding: "20px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  cardCaption: {
    fontFamily: "Open Sans",
    textAlign: "center",
  },
}))

export default function Book() {
  const classes = useStyles()

  return (
    <>
      <Container>
        <Hero
          title="From a Tear to Here"
          bodyText="A peek into the incredible journey of the Avery family. "
        />
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          direction="row-reverse"
        >
          <Grid item sm={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                component="img"
                alt="Paperback copies of the book"
                image={Tear}
                title="From a Tear to Here"
              />
              <CardContent>
                <Typography className={classes.cardCaption}>
                  The book is available to buy now by clicking the button below.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={7}>
            <Typography className={classes.bodyText}>
              Following a diagnosis of autism at age two and unable to speak
              until the age of six, this book follows the remarkable
              transformation of Aston Avery from a virtually out of control
              child needing professional intervention, to a confident
              well-spoken radio DJ. Taking you through the peaks and troughs of
              the Avery family's journey, From a Tear to Here has been written
              in the hopes that it may help other families in their position.
              <hr style={{ width: "15%", border: "1px solid #0072ab" }} />
              The book is available to purchase using a secure checkout system
              by clicking the button below. The price is £6, plus £1.99 postage.
              We at Team Avery sincerely hope you enjoy the book, and that it
              may help as many people as possible.
            </Typography>
          </Grid>
          <Checkout />
        </Grid>
      </Container>
    </>
  )
}

import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import { loadStripe } from "@stripe/stripe-js"
const buttonStyles = {
  fontSize: "1rem",
  textAlign: "center",
  color: "white",
  padding: "12px 60px",
  backgroundColor: "#0072ab",
  borderRadius: "50px",
  fontFamily: "Roboto",
  textTransform: "capitalize",
  margin: "5px",
}
const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
}
let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51Ic9AWKdbstNnqoAm2Gnry0ZPJWYoBGrbp0rW6YiXxjNnlu1MKEdKJTU5OHKlhBenif8FiKdXgXKKJXPQ9Ws48Y8007SQ9vs90"
    )
  }
  return stripePromise
}
const Checkout = () => {
  const [loading, setLoading] = useState(false)
  const redirectToCheckout = async event => {
    event.preventDefault()
    setLoading(true)
    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [
        { price: "price_1IcCNvKdbstNnqoAv9BtejYM", quantity: 1 },
        { price: "price_1IcCKjKdbstNnqoASXrIetUC", quantity: 1 },
      ],
      successUrl: `https://teamaveryessex.com/thanks/`,
      cancelUrl: `https://teamaveryessex.com/book/`,
      shippingAddressCollection: {
        allowedCountries: ["GB"],
      },
    })
    if (error) {
      console.warn("Error:", error)
      setLoading(false)
    }
  }
  return (
    <Button
      variant="contained"
      disabled={loading}
      style={
        loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
      }
      onClick={redirectToCheckout}
    >
      Buy the Book
    </Button>
  )
}
export default Checkout
